body h2,p{
    padding:0 !important; 
}a{
    text-decoration:none; 
    color:#000;
}
body{
    font-family:"JetBrains Mono", monospace;
}
.card1{
    background-image:url(/images/CUARTA.gif);
    background-size:cover;
    background-position:center;
   
    
}.card1 .titulos, .card1 .cantidad, .card2 .titulos, .card2 .cantidad.card3 .titulos{
    color:#fff !important; 

}.poemass-22{
    border:10px solid #fff !important;
}.options{
    margin-top:-3rem;
    justify-content:center; 
    overflow: hidden !important;
}
.card2{
    background:rgba(1, 1, 1, 0.5) url(/images/Espalda.JPG) no-repeat center center/cover;
}.card3{
    background-image:url(/images/22.jpg);
    background-size:cover;
    background-position:center;
    
   
    
}.card3 .titulos,.card4 .titulos,.card5 .titulos{
    color:#fff !important;
}.card4{
    background-image:url(/images/IMG_9409.jpg);
    background-size:cover;
    background-position:center;
}.card5{
    background-image:url(/images/IMG_8776.jpg);
    background-size:cover;
    background-position:center;
}
.container3{
    padding:2rem; 
}@media screen and (max-width:800px){
    .container3{
        margin-top:4rem !important; 
    }.titulo-nuevos{
        margin-bottom: -2rem;
        margin-top:2rem; 
    }
}

.slider-wrapper{
    position:flex; 
    width:100%; 
    height:98%; 
    max-width:30rem; 
    margin:0 auto;
    
}
.titulo-nuevos{
    font-size:3rem !important;
    text-align: center;
}
.slider{
    display:flex; 
    aspect-ratio: 16 / 9;
    overflow-x:auto; 
    scroll-snap-type:x mandatory;
    scroll-behavior: smooth;
    box-shadow:0 1.5rem 3rem -0.75rem hsla(0,0%,0%,0.25); 
    height: 20rem;
    width: 50%;
    margin-left: 7.5rem;
    scrollbar-width: none; /* Hide scrollbar on Firefox */
    -ms-overflow-style: none; /* Hide scrollbar on Internet Explorer and Edge */
}
.slider > *{
    flex:1 0 100%; 
    scroll-snap-align:start; 
    object-fit:cover; 
    opacity:1 !important; 
    transition: opacity 0.1s ease; /* Add transition for opacity */
    
}.titulos{
    opacity: 1 !important; /* Initially hide the titles */
    transition: opacity 0.5s ease !important; /* Add transition effect */
}

.slider .active .titulos {
    opacity: 0 ;
}.slider-nav{
    display:flex; 
    column-gap: 1rem;
    position:absolute; 
    bottom:revert; 
    left:50%; 
    transform:translateX(-50%); 
    z-index:1; 
    margin-top:1rem; 
}.slider-nav a{
    width:0.5rem; 
    height:0.5rem; 
    
    background-color:#000; 
    opacity:0.75;
    transition:opacity ease 250ms; 
}.slider-nav a:hover{
    opacity:1; 
}.slider::-webkit-scrollbar {
    display: none;
}@media screen and (min-width:768px){
    .slider-nav{
        bottom:5rem; 
    }.slider-wrapper{
        max-width:65rem;
    }.slider{
        height: 30rem;
        width:60%; 
        margin-left:12.5rem; 
    }
}.card{
    text-align: center;
    justify-content: center;
    width:75%; 
    height:100%; 
    margin:0 auto;
    border:5px solid #181818;
    align-content:space-around; 
    border-radius:0!important;
}.historia-detras2{
    padding-left:0; 
    font-size:0.7rem; 
    text-align:left; 
    color:#fff; 
    margin-right:1rem; 
    font-family: inherit;
}
.slider-wrapper:hover .titulos-portada,.slider-wrapper:hover .cantidad,.slider-wrapper:hover .imagenes{
    opacity: 0 !important;
}.historia-detras2{
    opacity:0; 
    margin-left:-4rem !important;
}.slider-wrapper:hover .historia-detras2{
    opacity:1!important; 
   
    
}.slider-wrapper:hover .card1{
    opacity:0.1; 
}


@media screen and (min-width:768px){
    .historia-detras2{
       
        margin-left:-3.5rem !important;
        font-size:1.5rem; 
    }
}